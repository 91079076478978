import { getGravatarUrl } from '../_shared/utils/users/usersUtils.ts';
import { IGravatarRepository } from './interfaces/IGravatarRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';

export const gravatarRepository: RepositoryWithContext<IGravatarRepository> = {
  async get(_, gravatarHash, abortSignal) {
    const url = getGravatarUrl(gravatarHash);
    if (!url) {
      return null;
    }

    let response: Response | null = null;
    try {
      response = await fetch(url, { signal: abortSignal });
    } catch {
      return null;
    }

    return {
      url,
      contentLength: (await response.blob()).size,
    };
  },
};
