import { parseError } from '@kontent-ai/errors';
import {
  captureException as captureExceptionToSentry,
  logger as sentryLogger,
} from '@sentry/react';
import { trackExceptionToAppInsights } from './applicationInsights.ts';

const logConsoleError: typeof console.error = (...params) => {
  // Do not spam console in production.
  if (self._clientConfig.logErrorsToConsole) {
    console.error(...params);
  }
};

function trackError(exception: unknown, error?: unknown): void {
  if (self._envConfig.appInsightsInstrumentationKey) {
    trackExceptionToAppInsights({ exception: parseError(exception, error) });
  }
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// The names of the functions below need to be kept in sync with the name in Sentry Issue Grouping.
// If out of sync, they’ll appear as the source of the issue.
// See https://docs.sentry.io/concepts/data-management/event-grouping/stack-trace-rules/
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export function logErrorToMonitoringToolWithCustomMessage<T>(
  message: string,
  error: Exclude<T, string>,
): void {
  logConsoleError(message, error);
  trackError(message, error);
  if (self._envConfig.isSentryEnabled) {
    captureExceptionToSentry(error, (scope) => scope.setTransactionName(message));
  }
}

export function logErrorToMonitoringTool<T>(error: Exclude<T, string>): void {
  logConsoleError(error);
  trackError(error);
  if (self._envConfig.isSentryEnabled) {
    captureExceptionToSentry(error);
  }
}

export function logErrorMessageToMonitoringTool(
  message: string,
  attributes?: Record<string, unknown>,
): void {
  logConsoleError(message, attributes);
  trackError(message);
  if (self._envConfig.isSentryEnabled) {
    sentryLogger.error(message, attributes);
  }
}
